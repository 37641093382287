<template>
    <div class="page">
        <el-form size="small" :inline="true" ref="searchForm" class="query-form" :model="searchForm"
                 @keyup.enter.native="refreshList()" @submit.native.prevent>
            <el-row>
                <el-col :span="4">
                    <el-form-item prop="name">
                        <el-input size="small" maxlength="66" v-model="searchForm.name" placeholder="请输入文件名称" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item>
                        <el-button type="primary" @click="search()" size="small" icon="el-icon-search">查询</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="bg-white top">
            <el-menu :default-active="searchForm.tabIndex" class="el-menu-demo" mode="horizontal" @select="handleSelectTab">
                <el-menu-item index="0">已归档</el-menu-item>
                <el-menu-item index="1">未归档</el-menu-item>
            </el-menu>
            <el-table
                    :data="dataList"
                    v-loading = "loading"
                    size="small"
                    height="calc(100% - 80px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column type="selection" width="50"></el-table-column>
                <el-table-column prop="sequenceNumber" label="顺序号"></el-table-column>
                <el-table-column prop="exhibitionName" label="所属展览"  show-overflow-tooltip></el-table-column>
                <el-table-column prop="directoryName" label="所属目录"  show-overflow-tooltip></el-table-column>
                <el-table-column prop="fileName" label="文件名称"  show-overflow-tooltip></el-table-column>
                <el-table-column prop="createDate" label="操作时间"></el-table-column>
                <el-table-column prop="createName" label="操作人"></el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchForm: {
                    name: '',
                    tabIndex:"0"
                },
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                loading: true,
                dataListSelections: [],
            }
        },
        mounted() {
            this.refreshList();
        },
        methods: {
            search(){
                this.pageNo = 1
                this.refreshList();
            },
            refreshList() {
                this.loading = true
                this.$axios(this.api.common.archiveTaskList,{
                    ...this.searchForm,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    if (data.status) {
                        this.dataList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            // tab切换
            handleSelectTab(index) {
                this.searchForm.tabIndex = index;
                this.refreshList()
            },
            // 多选
            selectionChangeHandle (val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
        },
    }
</script>

<style scoped>

</style>